<script setup>
import useUserHelper from "@/composables/User/useUserHelper";
const props = defineProps({
  role: String,
});
const { hasRole } = useUserHelper();
</script>

<template>
  <slot v-if="hasRole(props.role)"></slot>
</template>
