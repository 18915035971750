<script setup>
import useUser from "@/composables/User/useUser";

import { ref } from "vue";
import { useStore } from "vuex";
import useProvider from "@/composables/Provider/useProvider";
import { VueCookieNext } from "vue-cookie-next";
import IbiolaDialog from "@/components/Layouts/Dialog";
import { useI18n } from "vue-i18n";

import FormMultiSelect from "@/components/TRNC/Form/FormMultiSelect";
import {useRoute, useRouter} from "vue-router";

defineProps({});

const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const store = useStore();
const providers = ref();
const provider = store.getters.getProvider;

const { loadProviders } = useUser();

const { loadById } = useProvider();
const selectedProvider = ref({
  id: provider.id,
  name: provider.name + " (" + provider.id + ")",
});
const loading = ref(false);

const onProviderChange = async () => {
  await VueCookieNext.setCookie("pid", selectedProvider.value.id, { expire: '30d' });
  await loadById(selectedProvider.value.id);

  // delete all filters in local storage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.endsWith("-filters")) {
      localStorage.removeItem(key);
      i--;
    }
  }

  // console.log("route.params", route.params)
  // delete existing query
  await router.replace({query: {}});
  if (route.params.id || route.params.topicId) {
    const position = route.fullPath.search(
      `/${route.params.id || route.params.topicId}`
    );
    const url = route.fullPath.substring(0, position);
    location.replace(url);
  } else {

    location.reload();
  }
};

const confirmOpen = ref(false);

const openConfirm = () => {
  confirmOpen.value = true;
};

const closeConfirm = () => {
  selectedProvider.value = {
    id: provider.id,
    name: provider.name + " (" + provider.id + ")",
  };
  confirmOpen.value = false;
};

const selectRef = ref(null);
const init = ref(true);

const refreshOptions = async () => {
  if (init.value) {
    loading.value = true;
    await loadProviders();
    providers.value = store.getters.getProviders.map((provider) => ({
      id: provider.id,
      name: provider.name + " (" + provider.id + ")",
    }));
    loading.value = false;
    init.value = false;
  }
};
</script>

<template>
  <FormMultiSelect ref="selectRef" v-model="selectedProvider" :searchable="true" :options="providers" mode="single"
    valueProp="id" label="name" :canClear="false" :object="true" class="w-full lg:w-60 ml-auto mr-0" :loading="loading"
    @open="refreshOptions" @select="openConfirm" data-cy="change_provider" />
  <ibiola-dialog v-if="confirmOpen" :title="t('provider-change-confirm-title')" :sub-line="
    t('provider-change-confirm-copy', { provider: selectedProvider.name })
  " :open="confirmOpen" :primary-button-label="t('form-confirm')" :secondary-button-label="t('form-cancel')"
    @primary="onProviderChange" @secondary="closeConfirm"></ibiola-dialog>
</template>
