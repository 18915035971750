import { computed, inject, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { VueCookieNext } from "vue-cookie-next";

export default function useProvider() {
  const api = inject("$api");
  let provider = reactive({});
  const store = useStore();
  const route = useRoute();
  const toast = useToast();
  const { t } = useI18n();

  const load = () => {
    return new Promise((resolve, reject) => {
      if (store.getters.getProvider) {
        Object.assign(provider, store.getters.getProvider);
        return resolve(store.getters.getProvider);
      }

      return api
        .get("/provider")
        .then((response) => {
          if (response.status === 200) {
            Object.assign(provider, response.data.data);
            store.commit("setProvider", Object.assign({}, provider));
          }

          return resolve(provider);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const loadById = (id) => {
    return api
      .get("/providers/" + id)
      .then((resp) => {
        if (resp.status === 200) {
          store.commit("setProvider", resp.data.data);
          return resp?.data?.data;
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || t("provider-change-error"));
        return false;
      });
  };

  const logoUrl = computed(() => {
    let baseUrl = api.defaults.baseURL + "provider/logo?";
    let prov = null;

    if (VueCookieNext.isCookieAvailable("pname")) {
      return baseUrl + "name=" + VueCookieNext.getCookie("pname");
    }

    if (
      !VueCookieNext.isCookieAvailable("auth") &&
      (route.query.pid || VueCookieNext.isCookieAvailable("pid"))
    ) {
      let pid;
      if (VueCookieNext.isCookieAvailable("pid")) {
        pid = VueCookieNext.getCookie("pid");
      } else {
        pid = route.query.pid;
      }
      return baseUrl + "id=" + pid + "&t=" + new Date().getTime();
    }

    if (store.getters.getAuthConfiguration) {
      prov = store.getters.getAuthConfiguration;
      return baseUrl + "id=" + prov.id + "&t=" + new Date().getTime();
    }

    if (store.getters.getProvider) {
      prov = store.getters.getProvider;
      return baseUrl + "id=" + prov.id + "&t=" + new Date().getTime();
    }

    return baseUrl + "id=32" + "&t=" + new Date().getTime();
  });

  return {
    provider,
    load,
    logoUrl,
    loadById,
  };
}
