import { inject, reactive } from "vue";
import { useStore } from "vuex";

export default function useValues() {
    const api = inject('$api');
    let values = reactive({});
    const store = useStore();

    const load = () => {
        return new Promise((resolve, reject) => {
            return api.get('/provider/values')
                .then(response => {
                    if (response.status === 200) {
                        // console.log("values", response.data.data)
                        Object.assign(values, response.data.data);
                        store.commit('setValues', Object.assign({}, values));
                    }
                    return resolve(values)
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    };

    return {
        load,
    }
}