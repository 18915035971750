import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useStore } from "vuex";

export default function useUserHelper() {
  const { locale } = useI18n({ useScope: "global" });
  const store = useStore();
  const user = computed(() => store.state.User.user);

  /* does not work  */
  const getUserFullName = (userObject) => {
    return userObject.data.user.firstname + " " + userObject.data.user.lastname;
  };

  const getLanguage = (full = true) => {
    let language = "";
    if (full) {
      language = locale.value;
    } else {
      const lang = locale.value;
      language = lang.substring(0, 2);
    }

    return language;
  };

  const getUserEmail = () => {
    return user.value.contact.email;
  };

  const hasUserStatus = computed(() => {
    return user.value && user.value.status;
  });

  const isBookingAllowed = computed(() => {
    return (
      !hasUserStatus.value ||
      (isUserActive.value &&
        user.value.status.payment === "active" &&
        user.value.status.drivingLicence === "active")
    );
  });

  const isUserDataMissing = computed(() => {
    return hasUserStatus.value && user.value.status.user === "profileComplete";
  });

  const isUserDataCheck = computed(() => {
    return (
      hasUserStatus.value &&
      (user.value.status.user === "profileCheck" || isPendingMailVerify.value)
    );
  });

  const isPendingMailVerify = computed(() => {
    return hasUserStatus.value && user.value.status.user === "verifyMail";
  });

  const licenseStatus = () => {
    return hasUserStatus.value && user.value.status.drivingLicence;
  };

  const paymentStatus = () => {
    return hasUserStatus.value && user.value.status.payment;
  };

  const isUserActive = computed(() => {
    return !hasUserStatus.value || user.value.status.user === "active";
  });

  const hasRole = (role) => {
    const activeRole = user?.value?.details?.role?.name
    if (activeRole === role) return true
    else return false
  };

  return {
    getUserFullName,
    getUserEmail,
    getLanguage,
    isBookingAllowed,
    isUserActive,
    isUserDataMissing,
    isUserDataCheck,
    licenseStatus,
    paymentStatus,
    isPendingMailVerify,
    hasRole,
  };
}
