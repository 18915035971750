import { createI18n } from "vue-i18n";
import messagesDE from "./translations/de.json";
import { queryParams } from "../../scripts/getQueryParams";

export const getMessages = () => {
  return {
    "de-DE": messagesDE,
    "en-US": {
      "booking-ics-label-title": "Your booked vehicle at: {provider}",
      "booking-ics-label-description":
        "Booked vehicle: {vehicle} {'|'} Number plate: {numberPlate}",
    },
  };
};

const i18n = new createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "de-DE", // set locale
  fallbackLocale: "de-DE", // set fallback locale
  messages: queryParams.cypress ? null : getMessages(), // set locale messages
});

export default i18n;
