import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import qs from "qs";
import useTools from "../General/useTools";
import {useStore} from "vuex";

const useAlerts = () => {
    const api = inject("$api");

    const toast = useToast();
    const { generateErrorMessage } = useTools();

    const store = useStore();
    const { t } = useI18n();
    const loadAlerts = (params, signal = undefined) => {
        return new Promise((resolve, reject) => {
            const defaultParams = {
                limit: 10,
                page: 1,
                sort: "-id",
                filter: [],
            };
            function forceDoubleBreaks(content) {
                return content.replace(/\n+/g, '<br><br>');
            }


            return api.get('/user/notifications', {
                signal,
                params: {
                    ...defaultParams,
                    ...params},
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: true });
                },
            })
                .then(response => {
                    let notifications;
                    if (response.status === 200) {
                        notifications = response.data;
                        for (let notification of notifications.data) {
                            notification.content = forceDoubleBreaks(notification.content);
                        }


                        // store.commit('setAlerts', {data: notifications});
                    }

                    return resolve(notifications);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    };

    const loadAlertsCount = ( signal = undefined) => {
        return new Promise((resolve, reject) => {
            const defaultParams = {
                limit: 1,
                page: 1,
                sort: "-id",
                filter: {
                    unread: 1,
                },
            };


            return api.get('/user/notifications', {
                signal,
                params: {
                    ...defaultParams},
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: true });
                },
            })
                .then(response => {
                    let notifications;
                    if (response.status === 200) {
                        // console.log("alerts count",response.data.meta.pagination.total)
                        store.commit('setAlertsCount', response.data.meta.pagination.total);
                    }

                    return resolve(notifications);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    };

        let abortCtrl = new AbortController();

    const setAlertRead = (idString) => {
        return new Promise((resolve, reject) => {
            api.post('/user/notifications/actions', {
                    type: "setMessagesRead",
                    messages: idString
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        resolve(response);
                        // loadAlerts()
                        abortCtrl?.abort();
                        abortCtrl = new AbortController();
                        // console.log("loadAlerstCount")
                        loadAlertsCount(abortCtrl.signal)
                            .then(() => {
                                console.log("loadAlertsCount completed successfully");
                            })
                            .catch(error => {
                                console.error("loadAlertsCount failed", error);
                            });

                    } else {
                        reject(new Error(`Request failed with status ${response.status}`));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    const setAlertUnread = (id) => {
        let abortCtrl;
        return new Promise((resolve, reject) => {
            api.put('/user/notifications/' + id, {setMessageRead: false})
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        resolve(response);
                        abortCtrl?.abort();
                        abortCtrl = new AbortController();
                        loadAlertsCount(abortCtrl.signal);

                    } else {
                        reject(new Error(`Request failed with status ${response.status}`));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const exportAlerts = (filter = [], type) => {
        const parsedFilter = { ...filter };

        return new Promise((resolve, reject) => {
            api.post('/user/notifications/export', {
                format: type,
                filter: parsedFilter,
                }
            )
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        resolve(response);

                    } else {
                        reject(new Error(`Request failed with status ${response.status}`));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    return {
        loadAlerts,
        loadAlertsCount,
        setAlertRead,
        setAlertUnread,
        exportAlerts
    };
};

export default useAlerts;
