<script setup>
import { computed } from "@vue/runtime-core";
import Multiselect from "@vueform/multiselect";
import { useI18n } from "vue-i18n";
import {ref, watch} from "vue";
const props = defineProps({
  name: String,
  options: [Array, Function, Promise],
  modelValue: [Array, String, Object, Number, Boolean],
  trackBy: String,
  error: String,
  loadingg: Boolean,
});

const emit = defineEmits(["update:modelValue", "onChange", "onOpenDropdown"]);

const { t } = useI18n();

// const value = ref(props.modelValue);

// const value = computed(() => {
//   return props.modelValue;
// });
const value = computed({
  get: () => props.modelValue,
  set: newValue => emit('update:modelValue', newValue),
});


const onChange = (data) => {
  emit("onChange", data, props.name, props.modelValue);
  emit("update:modelValue", data);
};

const inputChanged = (query) => {
  if (!query) {
    return
  }
  emit("onInput", query)
}

const openDropdown = () => {
  emit("onOpenDropdown")
}

const containerClasses = computed(() => {
  let classes =
    "'cursor-pointer min-h-input-box relative mx-auto w-full flex items-center justify-end box-border cursor-pointer rounded-lg bg-gray-100 text-sm leading-snug outline-none hover:border-primary-300 hover:border-1";
  if (props.error) {
    classes += "  !border !border-danger";
  }

  return classes;
});

const multiselectRef = ref(null)

const resetField = () => {
  multiselectRef.value.clear();
};

// Add focus and blur methods
const focusMultiselect = () => {
  multiselectRef.value.focus();
};

const blurMultiselect = () => {
  multiselectRef.value.blur();
};

const updateField = () => {
  multiselectRef.value.updateItems(true);
};

defineExpose({ onChange, resetField, focusMultiselect, blurMultiselect, updateField });

</script>

<template>
  <Multiselect ref="multiselectRef" :options="options" :loading="props.loading" @search-change="inputChanged" @open="openDropdown" v-model="value" :trackBy="trackBy || undefined" valueProp="value" label="name" :data-cy="props.name"
    @change="onChange" :placeholder="t('form-placeholder-select')" :noOptionsText="t('form-multiselect-noOptions')"
    :noResultsText="t('form-multiselect-noResultsText')" tag="disabled" :classes="{
      container: containerClasses,
      containerDisabled: 'cursor-not-allowed',
      containerOpen: 'rounded-b-none',
      containerOpenTop: 'rounded-t-none',
      containerActive: 'ring-0 !border-primary-500 !border-2',
      singleLabel:
        'flex items-center h-full max-w-full absolute left-0 whitespace-pre top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
      singleLabelText:
        'overflow-ellipsis overflow-hidden block whitespace-pre max-w-full',
      multipleLabel:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
      search:
        'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-sm font-sans bg-gray-100 rounded-lg pl-3.5',

      tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 p-2',
      tag: 'bg-primary-50 border border-primary-500 text-sm  py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-pre-line',
      tagDisabled: 'pr-2 opacity-50',
      tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm group',
      tagRemoveIcon:
        'bg-multiselect-remove bg-center bg-no-repeat opacity-40 inline-block w-3 h-3 group-hover:opacity-80',
      tagsSearchWrapper:
        'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
      tagsSearch:
        'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-sm font-sans box-border w-full bg-gray-100',
      tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
      placeholder:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
      caret:
        'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-1 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
      caretOpen: 'rotate-180 pointer-events-auto',
      clear:
        'pr-3.5 relative z-1 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
      clearIcon:
        'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
      spinner:
        'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-1 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
      dropdown:
        'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full overflow-y-auto w-full border border-gray-300 -mt-px z-50 bg-gray-100 flex flex-col rounded-b',
      dropdownTop:
        '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
      dropdownHidden: 'hidden',
      options: 'flex flex-col p-0 m-0 list-none w-full',
      optionsTop: 'flex-col-reverse',
      group: 'p-0 m-0',
      groupLabel:
        'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
      groupLabelPointable: 'cursor-pointer',
      groupLabelPointed: 'bg-gray-300 text-gray-700',
      groupLabelSelected: 'bg-green-600 text-white',
      groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
      groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
      groupLabelSelectedDisabled:
        'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
      groupOptions: 'p-0 m-0',
      option:
        'flex items-center whitespace-pre-line justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
      optionPointed: 'text-gray-800 bg-gray-300',
      optionSelected: 'text-gray-900 bg-primary-50',
      optionDisabled: 'text-gray-300 cursor-not-allowed',
      optionSelectedPointed: 'text-gray-900 bg-primary-50 opacity-90',
      optionSelectedDisabled:
        'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
      noOptions: 'py-2 px-3 text-gray-600 bg-gray-100 text-left',
      noResults: 'py-2 px-3 text-gray-600 bg-gray-100 text-left',
      fakeInput:
        'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
      spacer: 'h-9 py-px box-content',
    }">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot  :name="slot" v-bind="scope || {}" />
    </template>
  </Multiselect>
</template>

<style>
.multiselect--active {
  z-index: 1;
}
</style>
