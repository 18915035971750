import { inject, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { VueCookieNext } from "vue-cookie-next";
import useTranslations from "../General/useTranslations";
import { queryParams } from "../../../scripts/getQueryParams";

export default function useUser() {
  const { locale } = useI18n({ useScope: "global" });
  const api = inject("$api");
  let user = reactive({});
  const store = useStore();

  onBeforeMount(() => {
    store.getters.getDesign;
  });

  const { loadTranslations } = useTranslations();

  const load = (force = false) => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
        locale.value = storeUser.details.language;
        const tmpUser = {};
        Object.assign(tmpUser, storeUser);
        tmpUser.birth = new Date(tmpUser.birth);
        if (!force) {
          return resolve(tmpUser);
        }
      }

      if (storeUser == null) {
        VueCookieNext.removeCookie("auth");
        return reject("User not logged in");
      }

      return api
        .get("/user")
        .then(async (response) => {
          if (response.status === 200) {
            Object.assign(user, response.data.data.user);
            const permissions = response.data.data.permissions;
            user.permissions = permissions;
            user.birth = new Date(user.birth);
            locale.value = user.details.language;

            if (!queryParams.cypress)
              await loadTranslations(user.details.language);

            store.commit("setUser", user);
            store.commit(
              "setProviderConfiguration",
              response.data.data.configuration
            );
            store.commit("setProvider", response.data.data.provider);
          }
          return resolve(user);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const loadProviders = () => {
    return new Promise((resolve, reject) => {
      const parameters = {
        limit: -1,
        sort: "name",
        fields: "id,name",
      };

      return api
        .get("/providers", { params: parameters })
        .then((response) => {
          if (response.status === 200) {
            store.commit("setProviders", response.data.data);
          }

          return resolve();
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const update = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .put("/users/" + id, data)
        .then((response) => {
          if (response.status === 200) {
            const updatedUser = response.data.data;

            Object.assign(user, updatedUser);
            user.birth = new Date(user.birth);
            locale.value = user.details.language;
            store.commit("setUser", Object.assign({}, user));

            return resolve(updatedUser);
          }
          return reject();
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const loadPaymentAccount = () => {
    return new Promise((resolve, reject) => {
      let storeUser = null;

      if (store.getters.getUser) {
        storeUser = store.getters.getUser;
      }
      if (storeUser == null) {
        return reject("User not logged in");
      }

      return api
        .get(
          "/users/" + storeUser.id + "/payment-accounts?includes=paymentAccount"
        )
        .then((response) => {
          let paymentAccount = {};
          if (
            response.status === 200 &&
            response.data.meta.pagination.total > 0
          ) {
            paymentAccount = response.data.data[0].paymentAccount;
          }

          return resolve(paymentAccount);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const createPaymentAccount = (data) => {
    return new Promise((resolve, reject) => {
      return api
        .post("/payment-accounts", data)
        .then((response) => {
          let paymentAccount = {};
          if (response.status === 200 && response.data.data) {
            paymentAccount = response.data.data.paymentAccount;
          }

          return resolve(paymentAccount);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const updatePaymentAccount = (id, data) => {
    return new Promise((resolve, reject) => {
      return api
        .put("/payment-accounts/" + id, data)
        .then((response) => {
          let paymentAccount = {};
          if (response.status === 200 && response.data.data) {
            paymentAccount = response.data.data.paymentAccount;
          }

          return resolve(paymentAccount);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  return {
    load,
    update,
    loadPaymentAccount,
    createPaymentAccount,
    updatePaymentAccount,
    loadProviders,
  };
}
