<template>
  <TransitionRoot as="template" :show="dialogOpen">
    <Dialog as="div" static class="fixed z-50 inset-0 overflow-y-auto" @close="closeHandler" :open="dialogOpen">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                         leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 w-80 lg:w-1/2"
              :class="containerClass">
            <div>
              <div class="mt-3 sm:mt-5">
                <DialogTitle as="div" class="text-lg text-center leading-6 font-semibold text-gray-900 mb-2">
                  {{ title }}
                </DialogTitle>
                <slot name="subLine"/>
                <div class="text-center">
                  <p v-if="!isMarkdown" class="text-sm leading-5 font-normal text-gray-900 whitespace-pre-line">
                    {{ subLine }}
                  </p>
                  <Markdown v-else id="markdown" :html="true" class="text-base leading-6 font-normal text-left mb-3"
                            :source="subLine" :plugins="[
                    {
                      plugin: makeLinksAbsolute
                    },
                    {
                      plugin: MarkdownItLinkAttributes,
                      options: { attrs: { target: '_blank', class: 'text-primary-500 underline hover:no-underline' } },
                    },
                  ]"/>
                  <slot></slot>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 flex flex-col" :class="{
              'lg:flex-row-reverse': secondaryButtonLabel !== null,
              'lg:flex-row justify-center': secondaryButtonLabel === null || !primaryButtonLabel,
            }">
              <slot name="button">
                <div v-if="primaryButtonLabel" class="w-full lg:w-1/2 ml-0 mb-2 lg:ml-2 lg:mb-0">
                  <ibiola-button-primary :label="primaryButtonLabel" @click="primaryClicked" :loadingIndicator="true"
                                         :loadingStatus="loading" :disabled="loading"
                                         data-cy="dialog-primary-button"></ibiola-button-primary>
                </div>
                <div v-if="secondaryButtonLabel !== null" class="w-full lg:w-1/2 mr-0 lg:mr-2">
                  <ibiola-button-secondary :label="secondaryButtonLabel" @click="secondaryClicked">
                  </ibiola-button-secondary>
                </div>
              </slot>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {onMounted, ref, watch, defineProps, defineEmits} from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import IbiolaButtonPrimary from "@/components/Buttons/ButtonPrimary";
import IbiolaButtonSecondary from "@/components/Buttons/ButtonSecondary";
import {string} from "yup";
import Markdown from "vue3-markdown-it";
import MarkdownItLinkAttributes from "markdown-it-link-attributes";
import useMarkdown from "@/composables/Tools/useMarkdown";

const {makeLinksAbsolute} = useMarkdown();


const props =defineProps({
  title: {
    required: true,
    type: String,
  },
  subLine: {
    required: true,
    type: String,
  },
  open: {
    required: false,
    type: Boolean,
    default: false,
  },
  primaryButtonLabel: {
    required: false,
    type: [String, Number],
  },
  secondaryButtonLabel: {
    required: false,
    type: [String, Number],
    default: null,
  },
  loading: {
    required: false,
    type: Boolean,
    default: false,
  },
  containerClass: {
    required: false,
    type: string,
    default: "",
  },
  defaultToSecondary: {
    required: false,
    type: Boolean,
    default: false,
  },
  isMarkdown: {
    required: false,
    type: Boolean,
  }
})
const emit = defineEmits(["opened", "closed", "primary", "secondary"]);
const dialogOpen = ref(false);

onMounted(() => {
  dialogOpen.value = props.open;
});

watch(
    () => props.open,
    (value) => {
      dialogOpen.value = value;

      if (value) {
        emit("opened");
      } else {
        emit("closed");
      }
    }
);

const primaryClicked = () => {
  emit("primary");
  // dialogOpen.value = false;
};
const secondaryClicked = () => {
  emit("secondary");
  // dialogOpen.value = false;
};

const closeHandler = () => {
  if(props.defaultToSecondary) {
    secondaryClicked();
  }
  emit("close");
};


</script>

<style scoped></style>
