import {inject} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import qs from "qs";
import useTools from "../General/useTools";

// import i18n from "@/plugins/IbiolaTranslation";

const useTranslations = () => {
    const api = inject("$api");

    const toast = useToast();
    const {generateErrorMessage} = useTools();
    const {t, setLocaleMessage} = useI18n();

    const loadTranslations = (lang, params, signal) => {
        const defaultParams = {
            filter: {
                section: "admin",
            },
        };

        return api
            .get(`/ui-translations/${lang.replaceAll("-", "_")}`, {
                signal,
                params: {
                    ...defaultParams,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {encode: false});
                },
            })
            .then((resp) => {
                if (resp.status === 200) {
                    setLocaleMessage(lang, resp?.data);
                    return true;
                }
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("translations-load-error"));
                return false;
            });
    };

    const getTranslationGroups = (filter) => {
        return api
            .get(`/translation-groups`, {
                params: {
                    filter: {
                        ...filter
                    }
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {encode: false});
                },
            })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp.data;
                }
            });
    }

    const updateTranslation = (translation) => {
        return api
            .put(`/translation-groups`, translation)
            .then((resp) => {
                if (resp.status === 200) {
                    return resp.data;
                }
            });
    }

    return {
        loadTranslations,
        getTranslationGroups,
        updateTranslation
    };
};

export default useTranslations;
