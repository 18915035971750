import { createStore } from "vuex";
import User from './modules/User';
import Booking from './modules/Booking';
import Bluetooth from './modules/Bluetooth';
import Values from './modules/Values';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

// eslint-disable-next-line no-unused-vars
let ls = new SecureLS({ isCompression: false }); // For Obfuscating
const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        User,
        Booking,
        Bluetooth,
        Values
    },

    strict: debug,
    plugins: [
        createPersistedState({
            // storage: {
            //     getItem: (key) => ls.get(key),
            //     setItem: (key, value) => ls.set(key, value),
            //     removeItem: (key) => ls.remove(key),
            // },
        }),
    ],
});