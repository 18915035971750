import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import qs from "qs";
import useTools from "../General/useTools";

const useInstantPayments = () => {
    const api = inject("$api");

    const toast = useToast();
    const { generateErrorMessage } = useTools();
    const { t } = useI18n();

    const generateBookingsFilter = (filterInput) => {
        const parsedFilter = { ...filterInput };

        if (parsedFilter["time.end"]) {
            parsedFilter["time.end"] = {
                "<=": parsedFilter["time.end"],
            };
        }

        if (parsedFilter["time.start"]) {
            parsedFilter["time.start"] = {
                ">=": parsedFilter["time.start"],
            };
        }

        if (parsedFilter["depositTransaction.amount"]) {
            const min = parsedFilter["depositTransaction.amount"];
            parsedFilter["depositTransaction.amount"] = {
                ">=": min,
            };
        }

        if (parsedFilter["reservationTransaction.amount"]) {
            const min = parsedFilter["reservationTransaction.amount"];
            parsedFilter["reservationTransaction.amount"] = {
                ">=": min,
            };
        }

        return parsedFilter;
    };

    const loadInstantPaymentsBookings = (params, signal) => {
        const defaultParams = {
            limit: 10,
            page: 1,
            sort: "-status",
        };

        const parsedFilter = generateBookingsFilter(params.filter);

        return api
            .get("/instant-billing/bookings", {
                signal: signal,
                params: {
                    ...defaultParams,
                    ...params,
                    filter: parsedFilter,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: true });
                },
            })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp.data;
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-bookings-load-error"));
                return false;
            });
    };

    const downloadInstantPaymentsBookings = (filter) => {
        const parsedFilter = generateBookingsFilter(filter);

        return api
            .get("/instant-billing/bookings/download", {
                params: {
                    filter: parsedFilter,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: true });
                },
                responseType: "blob",
            })
            .then((resp) => {
                if (resp.status === 200) {
                    const blob = new Blob([resp.data], { type: "text/csv" });
                    const url = URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `${new Date().toISOString()}_instant_payments_bookings.csv`;
                    a.click();
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-bookings-download-error"));
                return false;
            });
    };

    const sendInstantPaymentsBookingsAction = (params) => {
        return api
            .post(`/instant-billing/bookings/${params?.bookingId}/actions?type=${params?.actionType}`, {}, { responseType: params?.responseType })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp;
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-bookings-action-error"));
                return false;
            });
    };

    const loadInstantPaymentsAdditionalFees = (params, signal) => {
        const defaultParams = {
            limit: 10,
            page: 1,
            sort: "-status",
        };

        const parsedFilter = generateBookingsFilter(params.filter);

        return api
            .get("/instant-billing/additional-fees", {
                signal: signal,
                params: {
                    ...defaultParams,
                    ...params,
                    filter: parsedFilter,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: true });
                },
            })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp.data;
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-additional-fees-load-error"));
                return false;
            });
    };

    const exportInstantPaymentsAdditionalFees = (filter = [], type) => {
        const parsedFilter = generateBookingsFilter(filter);


        return api
            .post("/instant-billing/additional-fees/export", {
                    format: type,
                    filter: parsedFilter,
            })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp.data
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("customers-load-error"));
                return false;
            });
    };

    const exportInstantPaymentsBookings = (filter = [], type) => {
        const parsedFilter = generateBookingsFilter(filter);

        // if (parsedFilter["time.end"]) {
        //     parsedFilter["time.end"] = {
        //         "<=": parsedFilter["time.end"],
        //     };
        // }

        return api
            .post("/instant-billing/bookings/export", {
                    format: type,
                    filter: parsedFilter,
            })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp.data
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("customers-load-error"));
                return false;
            });
    };
    const downloadInstantPaymentsAdditionalFees = (filter) => {
        const parsedFilter = generateBookingsFilter(filter);

        return api
            .get("/instant-billing/additional-fees/download", {
                params: {
                    filter: parsedFilter,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { encode: true });
                },
                responseType: "blob",
            })
            .then((resp) => {
                if (resp.status === 200) {
                    const blob = new Blob([resp.data], { type: "text/csv" });
                    const url = URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `${new Date().toISOString()}_instant_payments_additional-fees.csv`;
                    a.click();
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-additional-fees-download-error"));
                return false;
            });
    };

    const sendInstantPaymentsAdditionalFeesAction = (params) => {
        return api
            .post(`/instant-billing/additional-fees/${params?.additionalFeeId}/actions?type=${params?.actionType}`, {}, { responseType: params?.responseType })
            .then((resp) => {
                if (resp.status === 200) {
                    return resp;
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-additional-fees-action-error"));
                return false;
            });
    };

    const hasEntries = () => {


        return api
            .get("/instant-billing/has-entries")
            .then((resp) => {
                if (resp.data) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                const error = generateErrorMessage(err?.response?.data);
                toast.error(error || t("instant-payments-bookings-load-error"));
                return false;
            });
    };

    return {
        loadInstantPaymentsBookings,
        exportInstantPaymentsAdditionalFees,
        exportInstantPaymentsBookings,
        downloadInstantPaymentsBookings,
        sendInstantPaymentsBookingsAction,
        loadInstantPaymentsAdditionalFees,
        downloadInstantPaymentsAdditionalFees,
        sendInstantPaymentsAdditionalFeesAction,
        hasEntries
    };
};

export default useInstantPayments;