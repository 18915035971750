import {DateTime} from "luxon";

const state = () => {
    return {
        past: null,
        current: null,
    }
};
const mutations = {
    initBooking(state) {
        state.past = null;
        state.current = null;
    },

    setPast(state, past) {
        if (past) {
            state.past = past;
        }
    },

    setCurrent(state, current) {
        if (current) {
            state.current = current;
        }
    },
};
const getters = {
    getPast(state) {
        if (!state.past) {
            return null
        }
        const bookings = JSON.parse(JSON.stringify(state.past));
        const convertedBookings = [];

        bookings.data.forEach(booking => {
            booking.time.start = new Date(booking.time.start);
            booking.time.end = new Date(booking.time.end);
            booking.time.minStart = new Date(booking.time.minStart);
            booking.time.maxEnd = new Date(booking.time.maxEnd);

            for (let i = 0; i < booking.logbook.length; i++) {
                booking.logbook[i].track.start = new Date(booking.logbook[i].track.start);
                booking.logbook[i].track.end = new Date(booking.logbook[i].track.end);
            }

            convertedBookings.push(booking);
        });

        // Sort descending to have newest booking as first
        convertedBookings.sort((a, b) => {
            return DateTime.fromJSDate(b.time.start).toMillis() - DateTime.fromJSDate(a.time.start).toMillis();
        });
        bookings.data = convertedBookings;

        return bookings;
    },

    getCurrent(state) {
        if (!state.current) {
            return null
        }
        const bookings = JSON.parse(JSON.stringify(state.current));
        const convertedBookings = [];

        bookings.data.forEach(booking => {
            booking.time.start = new Date(booking.time.start);
            booking.time.end = new Date(booking.time.end);
            booking.time.minStart = new Date(booking.time.minStart);
            booking.time.maxEnd = new Date(booking.time.maxEnd);

            for (let i = 0; i < booking.logbook.length; i++) {
                booking.logbook[i].track.start = new Date(booking.logbook[i].track.start);
                booking.logbook[i].track.end = new Date(booking.logbook[i].track.end);
            }

            convertedBookings.push(booking);
        });

        // Sort ascending to have current booking as first
        convertedBookings.sort((a, b) => {
            return DateTime.fromJSDate(a.time.start).toMillis() - DateTime.fromJSDate(b.time.start).toMillis();
        });
        bookings.data = convertedBookings;

        return bookings;
    },

    getDashboardBookings(state, getters) {
        if (!state.current) {
            return null
        }
        return getters.getCurrent.data.slice(0, 3);
    },

    getBooking: (state) => (id) => {
        let allBookings = [];
        if (state.current && state.current.data && state.past && state.current.data) {
            allBookings = [
                ...state.current.data,
                ...state.past.data,
            ];
        } else if (state.current && state.current.data) {
            allBookings = [
                ...state.current.data,
            ];
        } else if (state.past && state.current.past) {
            allBookings = [
                ...state.past.data,
            ];
        }

        const tmpBooking = allBookings.find(x => x.id === parseInt(id));
        let booking = null;
        if (tmpBooking) {
            booking = JSON.parse(JSON.stringify(tmpBooking));

            booking.time.start = new Date(booking.time.start);
            booking.time.end = new Date(booking.time.end);
            booking.time.minStart = new Date(booking.time.minStart);
            booking.time.maxEnd = new Date(booking.time.maxEnd);

            for (let i = 0; i < booking.logbook.length; i++) {
                booking.logbook[i].track.start = new Date(booking.logbook[i].track.start);
                booking.logbook[i].track.end = new Date(booking.logbook[i].track.end);
            }
        }

        return booking;
    },
};

export default {
    state,
    mutations,
    getters,
}