import { createApp } from "vue";
import App from "./App";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import i18n from "@/plugins/IbiolaTranslation";

// Plugins
import router from "./router";
import { VueCookieNext } from "vue-cookie-next";
import axios from "axios";
import { Cache } from "axios-extensions";
import { DateTime, Settings } from "luxon";
import VueCountdown from "@chenfengyuan/vue-countdown";
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu,
} from "v-tooltip";

// Start configuration of API
// Create own cache, which will be exported and can be reset on logout
// const FIVE_MINUTES = 1000 * 60 * 5;
const TWENTY_MINUTES = 1000 * 60 * 20;
const cache = new Cache({ maxAge: TWENTY_MINUTES, max: 100 });

const instance = axios.create({
  withCredentials: true,
});
import IbiolaApi from "./plugins/Api";

const api = new IbiolaApi();
api.init(instance);

import store from "@/store";
import setupInterceptors from "@/plugins/ApiInterceptors";

setupInterceptors(instance, cache, store);

// Start configuration of i18n
// import IbiolaTranslation from "@/plugins/IbiolaTranslation";

// // const ibiolaTranslation = new IbiolaTranslation();
// const messages = IbiolaTranslation.getMessages();

// const i18n = createI18n({
//   legacy: false, // you must set `false`, to use Composition API
//   locale: "de-DE", // set locale
//   fallbackLocale: "en-US", // set fallback locale
//   messages, // set locale messages
// });
Settings.defaultLocale = "de-DE";

import "@/plugins/Countries";

// CSS
import "./assets/css/index.css";
import "v-tooltip/dist/v-tooltip.css";

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";

import IbiolaText from "@/components/Items/Text";

import "./plugins/FontAwesome.js";
// import './registerServiceWorker'

const app = createApp(App);
if (process.env.NODE_ENV === "production") {
  let env = "production";
  let version = process.env.VUE_APP_VERSION;
  if (
    location.hostname.includes("ibiola-testing") ||
    location.hostname.includes("admin-test")
  ) {
    env = "testing";
  }
  Sentry.init({
    app,
    dsn: "https://d64fbeb620144fab95e038a556080a0c@sentry.ibiola.com/4",
    environment: env,
    release: "web-admin@" + version,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  });
}

// Toast Notifications
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// import Position for Toast
const toastOptions = {
  // You can set your default options here
  position: POSITION.TOP_RIGHT,
};

app.use(Toast, toastOptions);

// Add usage
app.use(router);
app.use(VueCookieNext);
app.use(i18n);
app.use(store);
app.component(VueCountdown.name, VueCountdown);
// Font Awesome
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
app.component("font-awesome-layers-text", FontAwesomeLayersText);
// Tooltip
app.directive("tooltip", VTooltip);
app.directive("close-popper", VClosePopper);
app.component("VDropdown", Dropdown);
app.component("VTooltip", Tooltip);
app.component("VMenu", Menu);

// TRONIC global components
import HasPermission from "@/components/TRNC/HasPermission";
app.component("has-permission", HasPermission);

// Text Global
app.component("IbiolaText", IbiolaText);

// Add globalProperties
app.provide("$api", instance);
app.provide("$cache", cache);
app.provide("$luxon", DateTime);

// Mount App
app.mount("#app");
