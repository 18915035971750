<!-- TODO wenn Fontawesome bleibt, dann Klassen alter Spinner löschen -->
<template>
  <div :class="defaultClass ? 'w-full flex items-center' : ''">
    <font-awesome-icon
      :icon="[iconStyleString, iconName]"
      :size="iconSize + 'x'"
      class="mx-auto"
      :class="[color]"
      spin
    >
    </font-awesome-icon>
    <!--    <div class="spinner-ring mx-auto">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "ibiola-loading-spinner",
  props: {
    iconName: {
      required: false,
      type: String,
      default: "spinner-third",
    },
    iconStyleString: {
      required: false,
      type: String,
      default: "far",
    },
    iconSize: {
      required: false,
      type: Number,
      default: 4,
    },
    color: {
      required: false,
      type: String,
      default: "text-primary-500",
    },
    defaultClass: {
      required: false,
      type: Boolean,
      default: true,
    }
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
/*.spinner-ring {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}

.spinner-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  margin: 8px;
  border: 8px solid theme('colors.primary.600');
  border-radius: 50%;
  animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: theme('colors.primary.600') transparent transparent transparent;
}

.spinner-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}*/
</style>
