import useDesign from "@/composables/General/useDesign";

const { setPrimaryFromConfiguration, setDefault } = useDesign();

const state = () => {
  return {
    token: null,
    user: null,
    provider: null,
    providerConfiguration: null,
    authConfiguration: null,
    design: null,
    providers: null,
  };
};
const mutations = {
  initUser(state, data = { themeReset: true }) {
    state.token = null;
    state.user = null;
    state.providers = null;
    state.provider = null;
    state.providerConfiguration = null;
    if (data.themeReset) {
      state.design = null;
      setDefault();
    }
  },

  initDesign(state) {
    state.design = null;
    setDefault();
  },

  setToken(state, token) {
    state.token = token;
  },

  setUser(state, user) {
    state.user = user;
  },

  setProvider(state, provider) {
    state.provider = provider;
    if (provider && provider.design) {
      state.design = provider.design;
      setPrimaryFromConfiguration(state.design);
    } else {
      setDefault();
    }
  },

  setProviderConfiguration(state, configuration) {
    state.providerConfiguration = configuration;
  },

  setSpecificProviderConfiguration(state, payload) {
    const keysToUpdate = new Set(Object.keys(payload.configuration));
    let updatedKeysCount = 0;

    function updateValues(obj, keysToUpdate) {
      if (updatedKeysCount === keysToUpdate.size) return;
      Object.keys(obj).forEach(key => {
        if (keysToUpdate.has(key)) {
          obj[key] = payload.configuration[key];
          updatedKeysCount++;
          if (updatedKeysCount === keysToUpdate.size) return;
        }
        if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Array)) {
          updateValues(obj[key], keysToUpdate);
        }
      });
    }

    updateValues(state.providerConfiguration, keysToUpdate);
  },


  setAuthConfiguration(state, configuration) {
    state.authConfiguration = configuration;
    if (configuration && configuration.design) {
      state.design = configuration.design;
      setPrimaryFromConfiguration(state.design);
    } else {
      setDefault();
    }
  },

  setDesign(state, design) {
    state.design = design;
  },

  setProviders(state, providers) {
    state.providers = providers;
  },
};
const getters = {
  getToken(state) {
    return state.token;
  },

  getUser(state) {
    return state.user;
  },

  getProvider(state) {
    return state.provider;
  },

  getProviderConfiguration(state) {
    const configuration = state.providerConfiguration;
    if (configuration && configuration.design) {
      setPrimaryFromConfiguration(state.design);
    }
    return state.providerConfiguration;
  },

  getAuthConfiguration(state) {
    const configuration = state.authConfiguration;
    if (configuration && configuration.design) {
      setPrimaryFromConfiguration(state.design);
    }
    return state.authConfiguration;
  },

  getDesign(state) {
    setPrimaryFromConfiguration(state.design);
    return state.design;
  },

  getProviders(state) {
    return state.providers;
  },
};

export default {
  state,
  mutations,
  getters,
};
