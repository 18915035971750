import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { VueCookieNext } from "vue-cookie-next";
import i18n from "@/plugins/IbiolaTranslation";
import store from "@/store";
import { ref } from "vue";
import permission from "@/validations/schemas/User/Permission";

const permissions = ref({});
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title
    ? i18n.global.t(to.meta.title, { id: to.params?.id }) +
    " | " +
    i18n.global.t("page-title-default")
    : i18n.global.t("page-title-default");
});
// TODO: route to bookings/restrictions/user if no access to bookings/restrictions
function hasPermission(path) {
  const getUser = store.getters.getUser
  const userPermissions = getUser.permissions
  // console.log("userPermissions", userPermissions)
  permissions.value = userPermissions;
  // if (path === 'dashboard') {
  //   if (!userPermissions['Dashboard']?.read) {
  //     return false;
  //   }
  // }
  if (path === 'license-management-license-list') {
    if (!userPermissions['License']?.read) {
      return false;
    }
  }
  if (path === 'exports-list') {
    if (!userPermissions['Export']?.read) {
      return false;
    }
  }
  if (path === 'customer-list') {
    if (!userPermissions['User']?.read) {
      return false;
    }
  }
  if (path === 'sentinel-list') {
    if (!userPermissions['SentinelData']?.read) {
      return false;
    }
  }
  if (path === 'customer-detail') {
    if (!userPermissions['User']?.read) {
      return false;
    }
  }
  if (path === 'customer-edit') {
    if (!userPermissions['User']?.update) {
      return false;
    }
  }
  if (path === 'customer-add') {
    if (!userPermissions['User']?.create) {
      return false;
    }
  }
  if (path === 'customer-keyid-media-list') {
    if (!userPermissions['KeyidMedia']?.read) {
      return false;
    }
  }
  if (path === 'customer-keyid-media-add') {
    if (!userPermissions['KeyidMedia']?.create) {
      return false;
    }
  }
  if (path === 'customer-keyid-media-edit') {
    if (!userPermissions['KeyidMedia']?.update) {
      return false;
    }
  }
  if (path === 'customer-cost-center-list') {
    if (!userPermissions['CostCenter']?.read) {
      return false;
    }
  }
  if (path === 'customer-cost-center-add') {
    if (!userPermissions['CostCenter']?.create) {
      return false;
    }
  }
  if (path === 'customer-cost-center-edit') {
    if (!userPermissions['CostCenter']?.update) {
      return false;
    }
  }
  if (path === 'customer-affiliate-list') {
    if (!userPermissions['Affiliates']?.read) {
      return false;
    }
  }
  if (path === 'customer-affiliate-add') {
    if (!userPermissions['Affiliates']?.create) {
      return false;
    }
  }
  if (path === 'customer-affiliate-edit') {
    if (!userPermissions['Affiliates']?.update) {
      return false;
    }
  }
  if (path === 'customer-payment-account-list') {
    if (!userPermissions['PaymentAccount']?.read) {
      return false;
    }
  }
  if (path === 'customer-payment-account-add') {
    if (!userPermissions['PaymentAccount']?.create) {
      return false;
    }
  }
  if (path === 'customer-payment-account-edit') {
    if (!userPermissions['PaymentAccount']?.update) {
      return false;
    }
  }


  if (path === 'crm-cases-list') {
    if (!userPermissions['CrmUserCase']?.read) {
      return false;
    }
  }
  if (path === 'crm-cases-add') {
    if (!userPermissions['CrmUserCase']?.create) {
      return false;
    }
  }
  if (path === 'crm-cases-detail') {
    if (!userPermissions['CrmUserCase']?.read) {
      return false;
    }
  }
  if (path === 'crm-cases-edit') {
    if (!userPermissions['CrmUserCase']?.update) {
      return false;
    }
  }
  if (path === 'bookings-list') {
    if (!userPermissions['Booking']?.read) {
      return false;
    }
  }
  if (path === 'booking-detail') {
    if (!userPermissions['Booking']?.read) {
      return false;
    }
  }
  if (path === 'booking-edit') {
    if (!userPermissions['Booking']?.update) {
      return false;
    }
  }
  if (path === 'booking-add') {
    if (!userPermissions['Booking']?.create) {
      return false;
    }
  }
  if (path === 'booking-deleted') {
    if (!userPermissions['BookingDeleted']?.read) {
      return false;
    }
  }
  if (path === 'booking-deleted-detail') {
    if (!userPermissions['BookingDeleted']?.read) {
      return false;
    }
  }
  if (path === 'booking-restrictions') {
    if (!userPermissions['BookingRestrictionUsage']?.read) {
      return false;
    }
  }
  if (path === 'booking-feedback') {
    if (!userPermissions['BookingFeedback']?.read) {
      return false;
    }
  }

  if (path === 'booking-restrictions-operating-hours-edit') {
    if (!userPermissions['BookingRestrictionUsage']?.update) {
      return false;
    }
  }
  if (path === 'booking-restrictions-special-operating-hours-add') {
    if (!userPermissions['BookingRestrictionUsage']?.create) {
      return false;
    }
  }
  if (path === 'booking-restrictions-special-operating-hours-edit') {
    if (!userPermissions['BookingRestrictionUsage']?.update) {
      return false;
    }
  }
  if (path === 'booking-restrictions-usage') {
    if (!userPermissions['BookingRestrictionUsage']?.read) {
      return false;
    }
  }
  if (path === 'booking-restrictions-usage-add') {
    if (!userPermissions['BookingRestrictionUsage']?.create) {
      return false;
    }
  }
  if (path === 'booking-restrictions-usage-edit') {
    if (!userPermissions['BookingRestrictionUsage']?.update) {
      return false;
    }
  }
  if (path === 'booking-restrictions-user') {
    if (!userPermissions['BookingRestrictionUser']?.read) {
      return false;
    }
  }
  if (path === 'booking-restrictions-user-add') {
    if (!userPermissions['BookingRestrictionUser']?.create) {
      return false;
    }
  }
  if (path === 'booking-restrictions-user-edit') {
    if (!userPermissions['BookingRestrictionUser']?.update) {
      return false;
    }
  }
  //
  if (path === 'vehicle-management-list') {
    if (!userPermissions['Vehicle']?.read) {
      return false;
    }
  }
  if (path === 'vehicle-management-add') {
    if (!userPermissions['Vehicle']?.create) {
      return false;
    }
  }
  if (path === 'vehicle-management-detail') {
    if (!userPermissions['Vehicle']?.read) {
      return false;
    }
  }
  if (path === 'vehicle-management-edit') {
    if (!userPermissions['Vehicle']?.update) {
      return false;
    }
  }
  if (path === 'vehicle-models-list') {
    if (!userPermissions['VehicleModel']?.read) {
      return false;
    }
  }
  if (path === 'vehicle-models-add') {
    if (!userPermissions['VehicleModel']?.create) {
      return false;
    }
  }
  if (path === 'vehicle-models-edit') {
    if (!userPermissions['VehicleModel']?.update) {
      return false;
    }
  }
  if (path === 'vehicle-map-tracking') {
    if (!userPermissions['VehicleTracking']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-map') {
    if (!userPermissions['CurrentStatusData']?.read) {
      return false;
    }
  }
  //
  if (path === 'vehicle-costs-list') {
    if (!userPermissions['CostElement']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-costs-edit') {
    if (!userPermissions['CostElement']?.update) {
      return false;
    }
  }

  if (path === 'vehicle-costs-add') {
    if (!userPermissions['CostElement']?.create) {
      return false;
    }
  }

  if (path === 'vehicle-fleets-list') {
    if (!userPermissions['Fleet']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-fleets-add') {
    if (!userPermissions['Fleet']?.create) {
      return false;
    }
  }

  if (path === 'vehicle-fleets-edit') {
    if (!userPermissions['Fleet']?.update) {
      return false;
    }
  }

  if (path === 'vehicle-fleets-locations-list') {
    if (!userPermissions['FleetLocation']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-fleets-locations-edit') {
    if (!userPermissions['FleetLocation']?.update) {
      return false;
    }
  }

  if (path === 'vehicle-fleets-locations-add') {
    if (!userPermissions['FleetLocation']?.create) {
      return false;
    }
  }

  if (path === 'vehicle-telemetry-devices-list') {
    if (!userPermissions['TelemetryHardware']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-telemetry-devices-add') {
    if (!userPermissions['TelemetryHardware']?.create) {
      return false;
    }
  }

  if (path === 'vehicle-telemetry-devices-edit') {
    if (!userPermissions['TelemetryHardware']?.update) {
      return false;
    }
  }
  if (path === 'vehicle-damage-cases-list') {
    if (!userPermissions['DamageCase']?.read) {
      return false;
    }
  }
  if (path === 'vehicle-damage-cases-add') {
    if (!userPermissions['DamageCase']?.create) {
      return false;
    }
  }
  if (path === 'vehicle-damage-cases-edit') {
    if (!userPermissions['DamageCase']?.update) {
      return false;
    }
  }
  if (path === 'vehicle-damage-cases-detail') {
    if (!userPermissions['DamageCase']?.read) {
      return false;
    }
  }
  //
  if (path === 'vehicle-files-basic-data') {
    if (!userPermissions['VehicleFile']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-files-basic-detail') {
    if (!userPermissions['VehicleFile']?.read) {
      return false;
    }
  }

  if (path === 'vehicle-files-basic-data-add') {
    if (!userPermissions['VehicleFile']?.create) {
      return false;
    }
  }

  if (path === 'vehicle-files-basic-data-edit') {
    if (!userPermissions['VehicleFile']?.update) {
      return false;
    }
  }

  if (path === 'vehicle-files-uploads') {
    if (!userPermissions['VehicleFileDocument']?.read) {
      return false;
    }
  }

  //
  if (path === 'accounting-dashboard') {
    if (!userPermissions['CostOverview']?.read) {
      return false;
    }
  }

  if (path === 'accounting-billing-list') {
    if (!userPermissions['BillingCycle']?.read) {
      return false;
    }
  }
  if (path === 'accounting-billing-detail') {
    if (!userPermissions['BillingCycle']?.read) {
      return false;
    }
  }

  if (path === 'accounting-billing-add') {
    if (!userPermissions['BillingCycle']?.create) {
      return false;
    }
  }

  if (path === 'accounting-additional-fee-list') {
    if (!userPermissions['AdditionalFee']?.read) {
      return false;
    }
  }

  if (path === 'accounting-additional-fee-add') {
    if (!userPermissions['AdditionalFee']?.create) {
      return false;
    }
  }

  if (path === 'accounting-additional-fee-edit') {
    if (!userPermissions['AdditionalFee']?.update) {
      return false;
    }
  }

  if (path === 'accounting-tariffs-list') {
    if (!userPermissions['PricingMembership']?.read) {
      return false;
    }
  }

  if (path === 'accounting-tariffs-detail') {
    if (!userPermissions['PricingMembership']?.read) {
      return false;
    }
  }

  if (path === 'accounting-tariffs-edit') {
    if (!userPermissions['PricingMembership']?.update) {
      return false;
    }
  }

  if (path === 'accounting-tariffs-add') {
    if (!userPermissions['PricingMembership']?.create) {
      return false;
    }
  }

  if (path === 'accounting-usage-tariffs-list') {
    if (!userPermissions['PricingUsage']?.read) {
      return false;
    }
  }

  if (path === 'accounting-usage-tariffs-add') {
    if (!userPermissions['PricingUsage']?.create) {
      return false;
    }
  }

  // if (path === 'accounting-usage-tariffs-add') {
  //   if (!userPermissions['PricingUsage']?.create) {
  //     return false;
  //   }
  // }

  if (path === 'accounting-usage-tariffs-detail') {
    if (!userPermissions['PricingUsage']?.read) {
      return false;
    }
  }

  if (path === 'accounting-usage-tariffs-edit') {
    if (!userPermissions['PricingUsage']?.update) {
      return false;
    }
  }

  if (path === 'accounting-trip-packages-list') {
    if (!userPermissions['TripPackage']?.read) {
      return false;
    }
  }

  if (path === 'accounting-trip-packages-add') {
    if (!userPermissions['TripPackage']?.create) {
      return false;
    }
  }

  // if (path === 'accounting-trip-packages-parentId-add') {
  //   if (!userPermissions['TripPackage']?.create) {
  //     return false;
  //   }
  // }

  if (path === 'accounting-trip-packages-edit') {
    if (!userPermissions['TripPackage']?.update) {
      return false;
    }
  }

  if (path === 'reporting-denied-vehicle-access-list') {
    if (!userPermissions['DeniedVehicleAccess']?.read) {
      return false;
    }
  }

  if (path === "faq-topic-list") {
    if (!userPermissions["Faq"]?.read) {
      return false;
    }
  }

  if (path === "faq-topic-add") {
    if (!userPermissions["Faq"]?.create) {
      return false;
    }
  }

  if (path === "faq-topic-edit") {
    if (!userPermissions["Faq"]?.update) {
      return false;
    }
  }

  if (path === "faq-topic-question-list") {
    if (!userPermissions["Faq"]?.read) {
      return false;
    }
  }

  if (path === "faq-topic-question-add") {
    if (!userPermissions["Faq"]?.create) {
      return false;
    }
  }

  if (path === "faq-topic-question-edit") {
    if (!userPermissions["Faq"]?.update) {
      return false;
    }
  }

  if (path === "provider-list") {
    if (!userPermissions["Mandant"]?.read) {
      return false;
    }
  }

  if (path === "provider-add") {
    if (!userPermissions["Mandant"]?.create) {
      return false;
    }
  }

  if (path === "logbook-list" || path === "logbook-combined-list") {
    if (!userPermissions["Logbook"]?.read) {
      return false;
    }
  }

  if (path === "logbook-edit") {
    if (!userPermissions["Logbook"]?.update) {
      return false;
    }
  }

  if (path === "settings-basic-data-edit") {
    if (!userPermissions["Mandant"]?.update) {
      return false;
    }
  }

  if (path === "settings-design-edit") {
    if (!userPermissions["MandantDesign"]?.update) {
      return false;
    }
  }

  if (path === "settings-third-party-edit") {
    if (!userPermissions["MandantAccessData"]?.update) {
      return false;
    }
  }

  if (path === "customer-settings") {
    if (!userPermissions["MandantSettingsUser"]?.read) {
      return false;
    }
  }

  if (path === "booking-settings") {
    if (!userPermissions["MandantSettingsBooking"]?.read) {
      return false;
    }
  }

  if (path === "vehicle-settings") {
    if (!userPermissions["MandantSettingsVehicle"]?.read) {
      return false;
    }
  }

  if (path === "accounting-settings") {
    if (!userPermissions["MandantSettingsAccounting"]?.read) {
      return false;
    }
  }

  if (path === "provider-settings") {
    if (store.getters.getUser.details.role.name !== 'superadmin') {
      return false;
    }
  }

  if (path === "settings-messages") {
    if (!userPermissions["MessageConfigurationProvider"]?.read) {
      return false;
    }
  }

  if (path === "customer-notifications") {
    if (!userPermissions["Notification"]?.read) {
      return false;
    }
  }

  if (path === "customer-notifications-add") {
    if (!userPermissions["Notification"]?.create) {
      return false;
    }
  }

  if (path === "customer-notifications-edit") {
    if (!userPermissions["Notification"]?.update) {
      return false;
    }
  }

  if (path === "reporting-kpi-list") {
    if (!userPermissions["Report"]?.read) {
      return false;
    }
  }

  if (path === "accounting-registration-tariffs-list") {
    if (!userPermissions["RegistrationTariff"]?.read) {
      return false;
    }
  }
  if (path === "accounting-registration-tariffs-edit") {
    if (!userPermissions["RegistrationTariff"]?.update) {
      return false;
    }
  }
  if (path === "registration-tariffs-add") {
    if (!userPermissions["RegistrationTariff"]?.create) {
      return false;
    }
  }

  if (path === "accounting-invoice-details-list") {
    if (!userPermissions["InvoiceDetail"]?.read) {
      return false;
    }
  }
  if (path === "accounting-invoice-details-add") {
    if (!userPermissions["InvoiceDetail"]?.create) {
      return false;
    }
  }
  if (path === "accounting-invoice-details-edit") {
    if (!userPermissions["InvoiceDetail"]?.update) {
      return false;
    }
  }

  if (path === "settings-translations") {
    if (!userPermissions["Translation"]?.update) {
      return false;
    }
  }

  if (path === 'cost-overview-list') {
  const providerConfig = store.getters.getProviderConfiguration;
    if (!providerConfig.general.isCostOverviewAvailable ||
        (providerConfig.general.isCostOverviewAvailable &&
        providerConfig.general.enableCostOverviewByCostElement) ) {
      return false;
    }
  }

  return true
}

router.beforeEach((to, from, next) => {
  to.meta.lastRoute = from.name;
  to.meta.lastParams = from.params;
  if (to.query.pid) {
    VueCookieNext.setCookie("pid", to.query.pid, { expire: '30d' });
    if (!to.meta.requiresAuth) {
      next({
        name: "splash",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }
  if (to.meta.requiresAuth && !VueCookieNext.isCookieAvailable("auth")) {
    // If route requires auth and user is not authed - redirect to login
    VueCookieNext.setCookie("redirectAfterLogin", to.fullPath, { expire: '1d' });
    next({
      path: "/",
      // query: {redirect: to.fullPath}
    });
  } else {
    if (to.name === "login" && VueCookieNext.isCookieAvailable("auth")) {
      next({
        path: "/dashboard",
      });
    } else if (to.meta.requiresAuth && VueCookieNext.isCookieAvailable("auth") && !hasPermission(to.name)) {
      // if (to.name === "dashboard") {
      //   next({
      //     path: "/customers"
      //   })
      // } else {

      next({
        path: "/dashboard"
      });
      // }
    } else {
      if (to.name === "reporting-denied-vehicle-access-list" && Object.keys(to.query).length === 0 && from.path !== '/reporting/denied-vehicle-access') {
        const timeFrom = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
        const updatedQuery = {
          timeFrom: timeFrom,
        }
        next({
          path: "/reporting/denied-vehicle-access",
          query: updatedQuery
        });
      }
      else if (to.name === "booking-restrictions" && !permissions.value['BookingRestrictionUsage']?.read) {
        return next({
          path: "/bookings/restrictions/user",
        });
      } else {
        next();
      }
    }
  }
});
export default router;
