const defaultTheme = require("tailwindcss/defaultTheme");
const svgToDataUri = require("mini-svg-data-uri");
const colors = require("tailwindcss/colors");

module.exports = {
  content: ["./public/**/*.html", "./src/**/*.{js,jsx,ts,tsx,vue}"],
  theme: {
    extend: {
      fontFamily: {
        sans: ["Inter", ...defaultTheme.fontFamily.sans],
      },
      height: {
        "input-box": "52px",
        "button-xl": "60px",
        "button-normal": "52px",
        "button-xs": "32px",
        "vehicle-type-mobile": "81px",
        "vehicle-type-desktop": "181px",
        "vehicle-item-mobile": "100px",
        "vehicle-item-desktop": "120px",
        "badge-small": "20px",
        badge: "24px",
        "top-bar": "72px",
      },
      minHeight: {
        "input-box": "52px",
        "info-message": "48px",
        "input-summary-single": "76px",
        "vehicle-item-mobile": "100px",
        "page-header": "110px",
      },
      width: {
        "vehicle-type-desktop": "182px",
      },
      backgroundImage: (theme) => ({
        "multiselect-caret": `url("${svgToDataUri(
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>`
        )}")`,
        "multiselect-spinner": `url("${svgToDataUri(
          `<svg viewBox="0 0 512 512" fill="${theme(
            "colors.primary.500"
          )}" xmlns="http://www.w3.org/2000/svg"><path d="M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z"></path></svg>`
        )}")`,
        "multiselect-remove": `url("${svgToDataUri(
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>`
        )}")`,
      }),
    },
    colors: {
      primary: {
        50: "var(--color-primary-50)",
        100: "var(--color-primary-100)",
        200: "var(--color-primary-200)",
        300: "var(--color-primary-300)",
        400: "var(--color-primary-400)",
        500: "var(--color-primary-500)",
        600: "var(--color-primary-600)",
        700: "var(--color-primary-700)",
        800: "var(--color-primary-800)",
        900: "var(--color-primary-900)",
      },
      gray: {
        // 50: 'var(--color-gray-50)',
        // 100: 'var(--color-gray-100)',
        // 200: 'var(--color-gray-200)',
        // 300: 'var(--color-gray-300)',
        // 400: 'var(--color-gray-400)',
        // 500: 'var(--color-gray-500)',
        // 600: 'var(--color-gray-600)',
        // 700: 'var(--color-gray-700)',
        // 800: 'var(--color-gray-800)',
        // 900: 'var(--color-gray-900)',

        50: "#F9FAFB",
        100: "#F3F4F6",
        200: "#E5E7EB",
        300: "#D1D5DB",
        400: "#9CA3AF",
        500: "#6B7280",
        600: "#4B5563",
        700: "#374151",
        800: "#1F2937",
        900: "#111827",
      },
      success: {
        dark: "var(--color-success-dark)",
        DEFAULT: "var(--color-success-default)",
        light: "var(--color-success-light)",
      },
      attention: {
        dark: "var(--color-attention-dark)",
        DEFAULT: "var(--color-attention-default)",
        light: "var(--color-attention-light)",
      },
      warning: {
        dark: "var(--color-warning-dark)",
        DEFAULT: "var(--color-warning-default)",
        light: "var(--color-warning-light)",
      },
      danger: {
        dark: "var(--color-danger-dark)",
        DEFAULT: "var(--color-danger-default)",
        light: "var(--color-danger-light)",
      },
      info: {
        dark: "var(--color-info-dark)",
        DEFAULT: "var(--color-info-default)",
        light: "var(--color-info-light)",
      },
      white: "#FFFFFF",
      yellow: colors.yellow,
    },
  },
  plugins: [require("@tailwindcss/forms")],
};
