import { cacheAdapterEnhancer } from "axios-extensions";
import axios from "axios";
import { Model } from "vue-api-query";

class IbiolaApi {
  init(instance) {
    this.setBaseUrl(instance);
    this.setHeaders(instance);
    // this.setCacheAdapter(instance);
    // this.appendRequestInterceptors(instance);
    // this.appendResponseInterceptors(instance, cache);
    this.setInstanceToQueryModel(instance);

    return instance;
  }

  setBaseUrl(instance) {
    const hostname = location.hostname;
    let apiUrl = "https://api.ibiola.com"; // Fallback URL
    let prefix = "api";
    if (hostname.includes("127.0.0.1") || hostname.includes("localhost")) {
      // For debugging purpose
      apiUrl = process.env.VUE_APP_API_URL;
    } else {
      // Replace subdomain app with api
      const firstDotIndex = hostname.indexOf(".");
      const subDomain = hostname.substring(0, firstDotIndex);
      if (subDomain === "admin-test") {
        prefix = "api-test";
        if (hostname.includes(".tim-graz.at") || hostname.includes(".tim-zentralraum.at")) {
          prefix = "api-v3-test";
        }
      } else if (subDomain === "admin") {
        prefix = "api";
        if (hostname.includes(".tim-graz.at") || hostname.includes(".tim-zentralraum.at")) {
          prefix = "api-v3";
        }
      }
      apiUrl = "https://" + prefix + hostname.substring(firstDotIndex) + "/v1/";
    }
    instance.defaults.baseURL = apiUrl;

    return instance;
  }

  setHeaders(instance) {
    instance.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Ibiola-Client": "web-admin/v3",
    };

    return instance;
  }

  setCacheAdapter(instance, cache) {
    instance.defaults.adapter(
      cacheAdapterEnhancer(axios.defaults.adapter, {
        useCache: true,
        defaultCache: cache,
      })
    );

    return instance;
  }

  setInstanceToQueryModel(instance) {
    // inject global axios instance as http client to Model
    Model.$http = instance;

    return instance;
  }
}

export default IbiolaApi;
