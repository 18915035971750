import {computed, nextTick, reactive} from "vue";

const state = reactive({
    values: {
    },
    alertsCount: 0,
    isVehicleCategoriesDone: false
});
const mutations = {
    setValues(state, values) {
        state.values = values;
    },
    setMissingCategories(state, payload) {
        if (!state.values.missingCategories) {
            state.values.missingCategories = false;
        }
        state.values.missingCategories = payload;
    },
    setMissingPositions(state, payload) {
        if (!state.values.missingPositions) {
            state.values.missingPositions = false;
        }
        state.values.missingPositions = payload;
    },
    setAlertsCount(state, count) {
        // console.log("setAlertsCount", count)
        state.alertsCount = count
        // console.log("setAlertsCount", state.alertsCount)
    },
    setVehicleCategories(state, payload) {
        // console.log("setVehicleCategories", payload)
        if (state.values.vehicleCategories) {
            state.values.vehicleCategories = []
        }
        state.values.vehicleCategories = [...payload]
        state.isVehicleCategoriesDone = true;
    },
    resetVehicleCategoriesFlag(state) {
        state.isVehicleCategoriesDone = false;
    },
    setFleetLocation(state, payload) {
        if (!state.values.fleetLocation) {
            state.values.fleetLocation = {};
        }
        state.values.fleetLocation[payload.id] = payload;
    },
    setFleet(state, payload) {
        if (!state.values.fleet) {
            state.values.fleet = {};
        }
        state.values.fleet[payload.id] = payload;
    },
    setFleetName(state, payload) {
        if (!state.values.fleetName) {
            state.values.fleetName = {};
        }
        state.values.fleetName[payload.id] = payload;
    },
    setVehicle(state, payload) {
        if (!state.values.vehicle) {
            state.values.vehicle = {};
        }
        state.values.vehicle[payload.id] = payload;
    },
    setCRMCategory(state, payload) {
        if (!state.values.crmCategory) {
            state.values.crmCategory = {};
        }
        state.values.crmCategory[payload.id] = payload;
    },
    setCRMTopic(state, payload) {
        if (!state.values.crmTopic) {
            state.values.crmTopic = {};
        }
        state.values.crmTopic[payload.id] = payload;
    },
    setCRMSubtopic(state, payload) {
        if (!state.values.crmSubtopic) {
            state.values.crmSubtopic = {};
        }
        state.values.crmSubtopic[payload.id] = payload;
    },
    setPricingMembership(state, payload) {
        if (!state.values.pricingMembership) {
            state.values.pricingMembership = {};
        }
        state.values.pricingMembership[payload.id] = payload;
    },
    setCheckBoxAction(state, payload) {
        state.values.action = {};
        state.values.action = payload;

    }
};
const getters = {
    getCheckBoxAction(state) {
        return state.values.action
    },
    getVehicleCategoriesDone(state) {
        return state.isVehicleCategoriesDone
    },
    getExportTypes(state) {
        // console.log("state.values.dashboardSort", state.values)
        return state.values.billing.exportTypes.map((type) => ({
            name: type.value,
            value: type.key,
        }));
    },
    getAlerts(state) {
        return state.alerts;
    },
    getMissingCategoriesPositions(state) {

        if (!state.values.missingCategories && !state.values.missingPositions) {
            return false
        }
        return true;

    },
    getAlertsRaw(state) {
        // console.log("state.alerts", state.alerts)
        return state.alerts.data;
    },

    getAlertsCount(state) {
        // console.log("getAlertsCount", state.alertsCount)
        return state.alertsCount;
    },
    getTranslations(state) {
        // console.log("state.values.translations", state.values)
        return state.values.translation
    },
    getFleetLocationById: (state) => (id) => {
        return state.values.fleetLocation?.[id];
    },
    getFleetById: (state) => (id) => {
        return state.values.fleet?.[id];
    },
    getFleetName: (state) => (id) => {
        return state.values.fleetName?.[id];
    },
    getVehicleById: (state) => (id) => {
        return state.values.vehicle?.[id];
    },

    getVehicleCategories(state) {
        return state.values.vehicleCategories?.map((category) => {
            return {
                name: category.name,
                value: category.id,
                damageCheckEnabled: category.damageCheck?.enabled
            }
        });
    },
    getIntegrationValues(state) {
        return state.values.integration;
    },

    getAllValues(state) {
        return state.values;
    },

    getVehicleValues(state) {
        return state.values.vehicle;
    },

    getFleetTypes(state) {
        return state.values.fleet.fleetType.map((type) => ({
            name: type.value,
            value: type.key,
        }));
    },

    getFleetType: (state) => (typeId) => {
        const id = parseInt(typeId);
        const fleetTypes = state.values.fleet.fleetType;
        const fleetType = fleetTypes.find((fleetType) => {
            return fleetType.key === id;
        });
        return fleetType;
    },

    getTelemetryDevices(state) {
        return state.values.vehicle.statusInterface
            .map((device) => ({
                name: device.value,
                value: device.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getDrivingLicences(state) {
        return state.values.drivingLicences.classes.map((licence) => ({
            name: licence.value,
            value: licence.key,
        }));
    },

    getDrivingLicence: (state) => (licenceKey) => {
        const drivingLicences = state.values.drivingLicences.classes;
        const drivingLicence = drivingLicences.find((licence) => {
            return licence.key === licenceKey;
        });
        return drivingLicence?.value;
    },

    getDrivingLicencesString: (state) => (licenceKeys) => {
        const licences = [];
        licenceKeys?.forEach((key) => {
            const licence = state.values.drivingLicences.classes.find((licence) => {
                return licence.key === key;
            });
            licences.push(licence.value);
        });
        return licences.join(", ");
    },

    getTelemetryDevice: (state) => (deviceKey) => {
        const telemetryDevices = state.values.vehicle.statusInterface;
        const device = telemetryDevices.find((device) => {
            return device.key === deviceKey;
        });
        return device?.value;
    },

    getMediaTypes: (state) => {
        return state.values.keyidmedia.mediatype
            .map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getMediaType: (state) => (mediaTypeKey) => {
        // console.log("mediaTypeKey", state.values.keyidmedia)
        const mediaTypes = state.values.keyidmedia.mediatype;
        const type = mediaTypes.find((type) => {
            return type.key == mediaTypeKey;
        });
        return type?.value;
    },
    getAccessTypes: (state) => {
        return state.values.keyidmedia.cardaccess
            .map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getAccessType: (state) => (accessTypeKey) => {
        // console.log("accestypes values", state.values.keyidmedia)
        // console.log("accestypeKey", accessTypeKey)
        const accessTypes = state.values.keyidmedia.cardaccess;
        const type = accessTypes.find((type) => {
            return type.key == accessTypeKey;
        });
        return type?.value;
    },
    getRestrictionUsageTypes: (state) => {
        return state.values.bookingRestrictions.usageRestrictionType
            .map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getRestrictionUsageType: (state) => (typeKey) => {
        const restrictionUsageTypes =
            state.values.bookingRestrictions.usageRestrictionType;
        const type = restrictionUsageTypes.find((type) => {
            return type.key == typeKey;
        });
        return type?.value;
    },
    getGenders: (state) => {
        return state.values.user?.gender
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getGender: (state) => (genderKey) => {
        const accessTypes = state.values.user?.gender;
        const type = accessTypes?.find((type) => {
            return type.key == genderKey;
        });
        return type?.value;
    },

    getAcquisitionTypes: (state) => {
        // console.log("state values", state.values)
        return state.values.vehicleFile?.acquisitionTypes
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getAcquisitionType: (state) => (acquisitionTypeKey) => {
        const accessTypes = state.values.vehicleFile?.acquisitionTypes;
        const type = accessTypes?.find((type) => {
            return type.key == acquisitionTypeKey;
        });
        return type?.value;
    },


    getCostTypes: (state) => {
        return state.values.vehicleFile?.costTypes
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getCostType: (state) => (costTypeKey) => {
        // console.log("costTypes", state.values.vehicleFile.costTypes)
        const accessTypes = state.values.vehicleFile?.costTypes;
        const type = accessTypes?.find((type) => {
            return type.key == costTypeKey;
        });
        return type?.value;
    },

    getEmissionStandards: (state) => {
        // console.log("getEmissionStandards", state.values.vehicleFile)
        return state.values.vehicleFile?.emissionStandards
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getEmissionStandard: (state) => (emissionStandardKey) => {
        const accessTypes = state.values.vehicleFile?.emissionStandards;
        const type = accessTypes?.find((type) => {
            return type.key == emissionStandardKey;
        });
        return type?.value;
    },

    getContractTypes: (state) => {
        return state.values.vehicleFile?.contractTypes
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getContractType: (state) => (contractTypeKey) => {
        // console.log("state", state.values)
        const accessTypes = state.values.vehicleFile?.contractTypes;
        const type = accessTypes?.find((type) => {
            return type.key == contractTypeKey;
        });
        return type?.value;
    },

    getDocumentTypes: (state) => {
        return state.values.vehicleFile?.documentTypes
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getDocumentType: (state) => (documentTypeKey) => {
        const accessTypes = state.values.vehicleFile?.documentTypes;
        const type = accessTypes?.find((type) => {
            return type.key == documentTypeKey;
        });
        return type?.value;
    },

    getAppointmentTypes: (state) => {
        return state.values.vehicleFile?.appointmentTypes
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getAppointmentType: (state) => (appointmentTypeKey) => {
        const accessTypes = state.values.vehicleFile?.appointmentTypes;
        const type = accessTypes?.find((type) => {
            return type.key == appointmentTypeKey;
        });
        return type?.value;
    },

    getAppointmentIntervals: (state) => {
        // console.log("getAppointmentIntervals", state.values.vehicleFile.appointmentIntervals)
        return state.values.vehicleFile?.appointmentIntervals
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getAppointmentInterval: (state) => (appointmentIntervalKey) => {
        const accessTypes = state.values.vehicleFile?.appointmentIntervals;
        const type = accessTypes?.find((type) => {
            return type.key == appointmentIntervalKey;
        });
        return type?.value;
    },

    getLangs: (state) => {
        return state.values.user?.language
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getLang: (state) => (langKey) => {
        const accessTypes = state.values.user?.language;
        const type = accessTypes?.find((type) => {
            return type.key == langKey;
        });
        return type?.value;
    },
    getLangAdmin: (state) => (langKey) => {
        const adminLanguages = state.values.ibiola?.languages;
        const lang = adminLanguages?.find((lang) => {
            return lang.key == langKey;
        });

        return lang?.value;
    },
    getLangsAdmin: (state) => {
        return state.values.ibiola?.languages
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getRoles: (state) => {
        return state.values.user?.roles
            ?.map((role) => ({
                name: role.value,
                value: role.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getRole: (state) => (roleKey) => {
        const roles = state.values.user?.roles;
        const role = roles?.find((tmpRole) => {
            return tmpRole.key == roleKey;
        });
        return role?.value;
    },
    getActionTypes: (state) => {
        return state.values.user?.actions
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getActionType: (state) => (actionTypeKey) => {
        const actionTypes = state.values.user?.actions;
        const actionType = actionTypes?.find((tmpActionType) => {
            return tmpActionType.key == actionTypeKey;
        });
        return actionType?.value;
    },

    getCRMActionTypes: (state) => {
        return state.values.crmUserCase?.actions
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getCRMActionType: (state) => (actionTypeKey) => {
        const actionTypes = state.values.crmUserCase?.actions;
        const actionType = actionTypes?.find((tmpActionType) => {
            return tmpActionType.key == actionTypeKey;
        });
        return actionType?.value;
    },

    getLogbookActionTypes: (state) => {
        return state.values.logbook?.actions
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getLogbookActionType: (state) => (actionTypeKey) => {
        const actionTypes = state.values.logbook?.actions;
        const actionType = actionTypes?.find((tmpActionType) => {
            return tmpActionType.key == actionTypeKey;
        });
        return actionType?.value;
    },

    getPaymentMethods: (state) => {
        return state.values.payment?.methods
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getPaymentMethod: (state) => (paymentMethodKey) => {
        const methods = state.values.payment?.methods;
        // console.log("methods", methods)
        // console.log("paymentMethodKey", paymentMethodKey)
        const method = methods?.find((tmpMethod) => {
            return tmpMethod.key == paymentMethodKey;
        });
        if (paymentMethodKey == 0) {
            // console.log("method", method)
        }
        return method?.value;
    },

    getPaymentStatuses: (state) => {
        return state.values.payment?.status
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getPaymentStatus: (state) => (paymentMethodKey) => {
        const statuses = state.values.payment?.status;
        const status = statuses?.find((tmpMethod) => {
            return tmpMethod.key == paymentMethodKey;
        });
        return status?.value;
    },

    getPaymentAccountStatuses: (state) => {
        return state.values.payment?.accountStatus
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getOnlinePaymentType: (state) => (typeKey) => {
        const types = state.values.payment?.onlinePaymentTypes;
        const type = types?.find((tmpMethod) => {
            return tmpMethod.key == typeKey;
        });
        return type?.value;
    },

    getBillingCycleStatuses: (state) => {
        return state.values.billing?.billingCycleStatus
            ?.map((status) => ({
                name: status.value,
                value: status.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getBillingCycleStatus: (state) => (statusKey) => {
        const statis = state.values.billing.billingCycleStatus;
        const status = statis.find((tmpStatus) => {
            return tmpStatus.key == statusKey;
        });
        return status?.value;
    },

    getBillingCycles: (state) => {
        return state.values.billing.billingCycle
        // .map((type) => ({
        //   name: type.value,
        //   value: type.key,
        // }))
        // .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBillingPeriodTypes: (state) => {
        state
        return state.values.billingPeriod?.type
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getBillingPeriodType: (state) => (typeKey) => {
        // console.log("state.values", state.values)
        const types = state.values.billingPeriod.type;
        const type = types.find((tmpType) => {
            return tmpType.key == typeKey;
        });
        return type?.value;
    },
    getBookingUtilizations: (state) => {
        const utilization = state.values.booking?.utilization
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.value < b.value);
        return utilization;
    },
    getBookingUtilization: (state) => (utilKey) => {
        const utilizations = state.values.booking?.utilization;
        const utilization = utilizations.find((tmpUtil) => {
            return tmpUtil.key == utilKey;
        });
        return utilization?.value;
    },

    getBookingType: (state) => (bookingTypeKey) => {
        const bookingTypes = state.values.booking.types;
        const bookingType = bookingTypes.find((tmpType) => {
            return tmpType.key == bookingTypeKey;
        });
        return bookingType?.value;
    },

    getBookingTypes: (state) => {
        return state.values.booking.types
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBookingUsageTypes: (state) => {
        // console.log("sate", state.values)
        return state.values.booking.utilization
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBookingByDateSorts: (state) => {
        // console.log("sate", state.values)
        return state.values.booking.byDateSorts
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getPOITypes: (state) => {
        // console.log("sate", state.values)
        return state.values.pointOfInterest.types
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
        // .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBookingAdminViews: (state) => {
        return state.values.booking.adminViews
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBookingListViews: (state) => {
        return state.values.booking.listViews
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBookingVariants: (state) => {
        return state.values.booking.variants
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getBookingVariantsForDefault: (state) => {
        return state.values.booking.variants
            ?.filter((type) => type.key !== 3)
            .map((type) => ({
                name: type.value,
                value: type.key
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },


    getBookingState: (state) => (stateKey) => {
        const states = state.values.booking.status;
        const choosenState = states.find((tmpState) => {
            return tmpState.key == stateKey;
        });
        return choosenState?.value;
    },

    getBookingStates: (state) => {
        return state.values.booking.status
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getBookingFilterStates: (state) => {
        return state.values.booking.statusFilter
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getVatType: (state) => (typeKey) => {
        const types = state.values.billing.vatTypes;
        const vatType = types.find((tmpType) => {
            return tmpType.key == typeKey;
        });
        return vatType?.value;
    },
    getVatTypes: (state) => {
        const types = state.values.billing.vatTypes;
        return types
            .map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getAdditionalFeeTypes: (state) => {
        const types = state.values.additionalFee.feeTypes;
        return types
            .map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getAdditionalFeeType: (state) => (typeKey) => {
        const types = state.values.additionalFee.feeTypes;
        const type = types.find((tmpType) => {
            return tmpType.key == typeKey;
        });
        return type?.value;
    },
    getAdditionalFeeTransactionType: (state) => (typeKey) => {
        const types = state.values.additionalFee.transactionTypes;
        const type = types.find((tmpType) => {
            return tmpType.key == typeKey;
        });
        return type?.value;
    },
    getAdditionalFeeTransactionTypes: (state) => {
        const types = state.values.additionalFee.transactionTypes;
        return types
            .map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getTripPackageWeekdays: (state) => {
        const types = state.values.tripPackage.weekdays;
        return types.map((state) => ({
            name: state.value,
            value: state.key,
        }));
    },
    getTripPackageWeekday: (state) => (weekdayKey) => {
        const weekdays = state.values.tripPackage.weekdays;
        const weekday = weekdays.find((tmpWeekday) => {
            return tmpWeekday.key == weekdayKey;
        });
        return weekday?.value;
    },

    getPaymentAccountActionTypes: (state) => {
        return state.values.paymentAccount?.actions
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getInstantEntriesStatus: (state) => (typeKey) => {
        const types = state.values.payment?.instantEntriesStatus;
        const type = types?.find((tmpMethod) => {
            return tmpMethod.key == typeKey;
        });
        return type?.value;
    },

    getInstantEntriesStatuses: (state) => {
        return state.values.payment?.instantEntriesStatus
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getShopSystems: (state) => {
        return state.values.payment?.paymentProviderShopSystem
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getTransactionResults: (state) => (typeKey) => {
        const types = state.values.payment?.transactionResults;
        const type = types?.find((tmpMethod) => {
            return tmpMethod.key == typeKey;
        });
        return type?.value;
    },

    getNotificationTypes: state => {
        return state.values.notifications.types.map(type => ({
            name: type.value,
            value: type.key
        }));
    },

    getNotificationCategories: state => {
        return state.values.notifications.categories.map(category => ({
            name: category.value,
            value: category.key
        }));
    },

    getSentinelTypes: state => {
        return state.values.telemetry.sentinelNotificationTypes.map(category => ({
            name: category.value,
            value: category.key
        }));
    },

    getExportStatuses: (state) => {
        return state.values.provider?.exportStatus
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getExportModules: (state) => {
        return state.values.provider?.exportModules
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    getReportCategories: (state) => {
        return state.values.report?.categories
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
    },

    getReportGroupings: (state) => {
        return state.values.report?.groupings
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
    },

    getReportSourceModels: (state) => {
        return state.values.report?.sourceModels
            ?.map((state) => ({
                name: state.value,
                value: state.key,
                type: state.type
            }))
    },

    getReportTypes: (state) => {
        // console.log("report vvalues",state.values)
        return state.values.report?.types
            ?.map((state) => ({
                name: state.value,
                value: state.key,
                category: state.category
            }))
    },

    getReportStatuses: (state) => {
        return state.values.report?.status
            ?.map((state) => ({
                name: state.value,
                value: state.key,
            }))
    },
    getFuelType: (state) => (utilKey) => {
        const fuels = state.values.vehicle?.fuel;
        // console.log("fuels",fuels)
        // console.log(utilKey)
        const fuel = fuels.find((tmpUtil) => {
            return tmpUtil.key == utilKey;
        });
        return fuel?.value;
    },
    getFuelTypes: (state) => {
        return state.values.vehicle?.fuel
            ?.map((type) => ({
                name: type.value,
                value: type.key,
            }))
    },
    getCRMCategory: (state) => (utilKey) => {
        const categories = state.values.crmCategory;
        return categories?.[utilKey];
    },
    getCRMTopic: (state) => (utilKey) => {
        const topics = state.values.crmTopic;
        // console.log("topics", topics)
        return topics?.[utilKey];
    },
    getCRMSubtopic: (state) => (utilKey) => {
        const subtopics = state.values.crmSubtopic;
        // console.log("subtopics", subtopics)
        return subtopics?.[utilKey];
    },
    getPricingMembership: (state) => (utilKey) => {
        const pricingMembership = state.values.pricingMembership;
        // console.log("pricingMembership", subtopics)
        return pricingMembership?.[utilKey];
    },
    getLivePositions: (state) => {
        return state.values?.map?.livePositions.map(pos => ({
            name: pos.value,
            value: pos.key
        }))
    },
    getProviderTypes: (state) => {
        return state.values?.general?.providerType.map(type => ({
            name: type.value,
            value: type.key
        }))
    },
    getPermissionSubsets: (state) => {
        return state.values?.general?.permissionSubsets.map(type => ({
            name: type.value,
            value: type.key
        }))
    },
};


export default {
    state,
    mutations,
    getters,
};
