import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import qs from "qs";
import useTools from "../General/useTools";

const useAdditionalFee = () => {
  const api = inject("$api");

  const toast = useToast();
  const { generateErrorMessage } = useTools();
  const { t } = useI18n();

  const getAdditionalFeeFilter = (filter) => {
    const parsedFilter = { ...filter };

    if (parsedFilter["amount"]) {
      const min = parsedFilter["amount"][0];
      const max = parsedFilter["amount"][1];
      parsedFilter["amount"] = {
        ">=": min,
        "<=": max,
      };
    }

    return parsedFilter;
  };

  const loadAdditionalFees = (params, signal) => {
    const defaultParams = {
      limit: 10,
      page: 1,
      sort: "-id",
    };

    const parsedFilter = getAdditionalFeeFilter(params.filter);

    return api
      .get("/additional-fees", {
        signal: signal,
        params: {
          ...defaultParams,
          ...params,
          filter: parsedFilter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { encode: true });
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.data;
        }
        return false;
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fees-load-error"));
        return false;
      });
  };

  const exportAdditionalFees = (filter = [], type) => {
    const parsedFilter = getAdditionalFeeFilter(filter);


    return api
        .post("/additional-fees/export", {
                     format: type,
            filter: parsedFilter,
        })
        .then((resp) => {
          if (resp.status === 200) {
            return resp.data
          }
          return false;
        })
        .catch((err) => {
          const error = generateErrorMessage(err?.response?.data);
          toast.error(error || t("accounting-additional-fee-download-error"));
          return false;
        });
  };
  const downloadAdditionalFees = (filter) => {
    const parsedFilter = getAdditionalFeeFilter(filter);

    return api
      .get("/additional-fees/download", {
        params: {
          filter: parsedFilter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { encode: true });
        },
        responseType: "blob",
      })
      .then((resp) => {
        if (resp.status === 200) {
          const blob = new Blob([resp.data], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${new Date().toISOString()}_additional-fees.csv`;
          a.click();
        }
        return false;
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fee-download-error"));
        return false;
      });
  };

  const loadAdditionalFeeFieldAccess = (params, signal) => {
    const defaultParams = {
      type: "create",
    };

    return api
      .get("/additional-fees/field-access", {
        signal,
        params: {
          ...defaultParams,
          ...params,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { encode: false });
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.data.data;
        }
        return false;
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ||
          t("accounting-additional-fee-load-fieldAccess-error")
        );
        return false;
      });
  };

  const loadAdditionalFee = (id, signal, params) => {
    return api
      .get(`/additional-fees/${id}`, {
        signal,
        params: {
          ...params,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          return resp?.data;
        }
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fee-load-error"));
        return false;
      });
  };

  const addAdditionalFee = (fee) => {
    return api
      .post(`/additional-fees`, fee)
      .then((resp) => {
        if (resp.status === 200) {
          return resp.data.data;
        }
        return false;
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fee-add-error"));
        return false;
      });
  };

  const editAdditionalFee = (fee) => {
    return api
      .put(`/additional-fees/${fee.id}`, fee)
      .then((resp) => {
        if (resp.status === 200) {
          return resp.data.data;
        }
        return false;
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fee-edit-error"));
        return false;
      });
  };

  const delAdditionalFee = (additionalFeeId) => {
    return api
      .delete(`/additional-fees/${additionalFeeId}`)
      .then((resp) => {
        if (resp.data.success) {
          return true;
        }
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fee-del-error"));
        return false;
      });
  };

  const loadAdditionalFeeHistory = (additionalFeeId, signal) => {
    return api
      .get(`/additional-fees/${additionalFeeId}/history`, {
        signal,
        params: {},
      })
      .then((resp) => {
        if (resp.status === 200) {
          return resp?.data;
        }
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("accounting-additional-fee-history-error"));
      });
  };

  const hasEntries = () => {


    return api
      .get("/instant-billing/has-entries")
      .then((resp) => {
        if (resp.data) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        const error = generateErrorMessage(err?.response?.data);
        toast.error(error || t("instant-payments-bookings-load-error"));
        return false;
      });
  };

  //   const loadVatTypes = (params, signal) => {
  //     return api
  //       .get("/vat-types", { signal, params })
  //       .then((resp) => {
  //         if (resp.status === 200) {
  //           return resp.data.data;
  //         }
  //         return false;
  //       })
  //       .catch((err) => {
  //         const error = generateErrorMessage(err?.response?.data);
  //         toast.error(error || t("accounting-tariffs-load-vat-types-error"));
  //         return false;
  //       });
  //   };

  return {
    loadAdditionalFees,
    exportAdditionalFees,
    downloadAdditionalFees,
    loadAdditionalFeeFieldAccess,
    loadAdditionalFee,
    addAdditionalFee,
    editAdditionalFee,
    delAdditionalFee,
    loadAdditionalFeeHistory,
    hasEntries,
  };
};

export default useAdditionalFee;
